<template>
  <div class="container">
    <h1 class="center">{{ data.poll.name ? data.poll.name.fr : '' }}</h1>
    <div class="row" v-for="(row, index) in data.poll.questions" :key="'row' + index">
      <vue-echarts
        :option="getOptions(row)"
        style="height: 300px; width: 400px"
        :ref="'row-' + index + 'chart-1'"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, reactive, toRefs, ref } from 'vue'
import ApiClient from '@/services/axios'
import { VueEcharts } from 'vue3-echarts'

export default defineComponent({
  components: {
    VueEcharts,
  },
  props: {
    id: {
      type: Object,
      default: () => '',
    },
  },
  setup(props) {
    const id = ref(props.id._id)
    let data = reactive({
      poll: {},
    })
    const chartData = ref([])
    ApiClient.post('/poll/filter', { query: { _id: id.value } })
      .then((res) => (data.poll = res.data[0]))
      .catch(() => {})
    ApiClient.post('/poll_vote/filter', {
      query: { poll: id.value },
      aggregation: [
        {
          $project: {
            answer: 1,
          },
        },
      ],
    })
      .then((res) => {
        const votes = res.data
        data.poll.questions = [...data.poll.questions].map((question) => {
          return {
            ...question,
            votes: question.options.map((_) => 0),
          }
        })
        const addVote = (questionIndex, vote) => {
          let question = { ...data.poll.questions[questionIndex] }
          const optionIndex = question.options.indexOf(vote)
          if (optionIndex != -1) question.votes[optionIndex] += 1
          return question
        }

        votes.forEach((vote) => {
          const { answer } = vote
          for (const [key, value] of Object.entries(answer)) {
            const validKey = parseInt(key)
            console.log(validKey)
            if (typeof validKey !== 'undefined')
              data.poll.questions[validKey] = addVote(validKey, value)
          }
        })
        console.info(votes)
      })
      .catch(() => {})

    const getOptions = (source) => {
      const { question, options, votes } = source
      return {
        title: {
          text: question,
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}',
        },
        xAxis: {
          type: 'category',
          data: options,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: votes,
            type: 'bar',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                color: 'rgba(0, 0, 2, 0.5)',
              },
            },
            itemStyle: {
              color: 'rgba(204, 27, 53)',
            },
          },
        ],
      }
    }
    return {
      id,
      getOptions,
      data,
    }
  },
})
</script>
