<template>
  <div class="container mt-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/polls" tag="a">{{ $t('menu.debates') }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page" v-if="data">
          {{ data.name[trad] }}
        </li>
      </ol>
    </nav>
    <div class="card main" v-if="data">
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-between">
            <div class="col-5">
              <div class="date">
                <i class="fe fe-calendar"></i>
                {{ moment(data.createdAt).locale('fr', localization).format('DD/MM/YYYY HH:mm') }}
              </div>
            </div>
            <div class="col-2 col-md-1">
              <a-select
                :default-value="trad"
                v-model="trad"
                style="width: 4, 5rem; float: right"
                @change="handleChange"
              >
                <a-select-option value="fr"> Fr </a-select-option>
                <a-select-option v-if="data.name.en" value="en"> En </a-select-option>
                <a-select-option v-if="data.name.ar" value="ar"> ع </a-select-option>
              </a-select>
            </div>
          </div>
          <h3 class="title">
            {{ data.name[trad] }}
          </h3>
          <br />
        </div>
        <div class="col-12">
          <div class="row" v-for="(question, index) in data.questions" :key="question">
            <div class="col-12">
              <div class="card-title">
                {{ index + 1 + '. ' + question.question }}
              </div>
              <div class="card-body">
                <div class="row">
                  <div clas="col-12">
                    <form>
                      <label v-for="option in question.options" :key="option">
                        <input
                          type="radio"
                          v-model="answers[index]"
                          :name="option"
                          :value="option"
                        />
                        <span>{{ option }}</span>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr />
        <input
          type="submit"
          name="submit"
          @click="submitPoll"
          value="Envoyer"
          class="btn btn-primary btn-block rounded-0 py-2"
        />
      </div>
    </div>
    <div v-else class="m-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow text-primary" role="status"></div>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      @ok="handleOk"
      :title="$t('general.pollStatsTitle')"
      :footer="null"
    >
      <pollStatCard
        :id="data"
      />
    </a-modal>
  </div>
</template>
<script>
import apiAxios from '@/services/axios'
import moment from 'moment'
import localization from 'moment/locale/fr'
import pollStatCard from '@/components/pollStats'

export default {
  name: 'Poll',
  components: { pollStatCard },

  data: () => ({
    visible: false,
    data: null,
    apiUrl: process.env.VUE_APP_API_URL,
    localization,
    trad: 'fr',
    answers: {},
  }),
  created() {
    this.getData()
  },
  methods: {
    moment,
    handleOk: function (e) {
      this.visible = false;
    },
    handleChange(value) {
      this.trad = value
    },
    getData() {
      apiAxios
        .post('/poll/filter', {
          query: { _id: this.$route.params.id /*, status: 'active' */ },
        })
        .then((res) => {

          if (res.data[0].name.en && this.$i18n.locale == 'en-US') {
            this.trad = 'en'
          }

          if (res.data[0].name.ar && this.$i18n.locale == 'ar-EG') {
            this.trad = 'ar'
          }
          this.data = res.data[0]
        })
        .finally(() => {
                    //console.log(this.data)
        })
    },
    
    openNotification(type, title, desc) {
      this.$notification[type]({
        message: title,
        description: desc,
      })
    },
    submitPoll() {
      //ip control
//this.getStats();

      fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then((response) => response.text())
        .then((response) => {
          response = response
            .trim()
            .split('\n')
            .reduce(function (obj, pair) {
              pair = pair.split('=')
              return (obj[pair[0]] = pair[1]), obj
            }, {})
          if (Object.keys(this.answers).length != this.data.questions.length) {
            this.openNotification('error', 'Sondage', 'Remplir tous les champs!')
          } else {
            console.log(response.ip)
            let data = {
              answer: this.answers,
              poll: this.data._id,
              ip: response.ip,
            }
            apiAxios
              .put('/poll_vote', { data })
              .then((res) => {
                this.openNotification('success', 'Sondage', 'Reponse enregistrée!')
                this.answers = []
                
              })
              .catch((e) => {
                this.openNotification('error', 'Sondage', "Erreur d'envoie , Vous avez déja envoyé une réponse !")
              }).finally(()=>{
                this.visible=true;
              })
          }
        })
    },
    trimWords(value, number) {
      if (!value) return ''
      if (value.split(' ').length < number) return value
      return value.split(' ').splice(0, number).join(' ') + '...'
    },
    ToText(HTML) {
      var input = HTML
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ /g, ' ')
        .replace(/>/g, ' ')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.main {
  border: 2px solid $primary;
}

.card-title {
  color: $secondary;
  border-top: 2px solid rgb(240, 240, 240);
  padding-top: 10px;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #666666;
}

.section-title h2::after {
  content: '';
  height: 1px;
  display: inline-block;
  background: $primary;
  margin: 4px 10px;
}

.image > img {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.title {
  margin-bottom: 8px;
  font-size: 2.3rem;
}

.card {
  border-radius: 0;
  margin: auto 0;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
}

.categorie {
  font-size: 14px;
  font-style: italic;
  border-bottom: 2px solid $primary;
  width: 7em;
  text-align: left;
  margin: 10px 0;
}

.date {
  color: $primary;
}

.icons a {
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
  font-size: 24px;

  height: 30px;
  line-height: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  background-color: $primary;
  color: white;
}
.icons a:hover {
  background: white;
  background-color: rgba(0, 0, 0, 0.05);
  color: $primary !important;
}

.downloads {
  font-size: 10px;
  font-style: italic;
}

.watched {
  color: $primary;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 18px;
  font-weight: 600;
}

.keys {
  color: $primary;
  font-size: 16px;
  font-weight: 600;
}

.key {
  font-size: 17px;
  margin-left: 5px;
}

form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */
  /*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: mix(#fff, $primary, 84%);
      &:before {
        box-shadow: inset 0 0 0 0.4375em $primary;
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em; // or something higher...
    transition: 0.25s ease;
    &:hover {
      background-color: mix(#fff, $primary, 84%);
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em $primary;
    }
  }
}

// End Codepen spesific styling
</style>
